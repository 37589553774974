import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiReact,
  DiNodejsSmall,
  DiGit,
  DiHtml5,
  DiCss3,
  DiBootstrap,
} from "react-icons/di";
import {
  SiFirebase,
  SiNextdotjs,
  SiTailwindcss,
  SiAzurefunctions,
  SiJavascript,
} from "react-icons/si";
import {
  FiGithub,
} from "react-icons/fi"

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={1} className="tech-icons">
        <DiHtml5 color="#d84a24" />
        <p>HTML</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <DiCss3 color="#2191ec" />
        <p>CSS</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <SiJavascript color="#ebd41c" />
        <p>JavaScript</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <DiReact color="#5ccfee" />
        <p>React</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <SiNextdotjs />
        <p>Next.js</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <DiNodejsSmall color="#509640" />
        <p>Node.js</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <DiGit color="#e44c31"/>
        <p>Git</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <FiGithub />
        <p>Github</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <SiFirebase color="#f2c132" />
        <p>Firebase</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <DiBootstrap color="#7512ec" />
        <p>Bootstrap</p>
      </Col>
      <Col xs={4} md={1} className="tech-icons">
        <SiTailwindcss color="#3f9faa" />
        <p>Tailwind.css</p>
      </Col>
    </Row>
  );
}

export default Techstack;

import React from "react"
import Card from "react-bootstrap/Card"
import { ImPointRight } from "react-icons/im"

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }}>
            Hi, I am <span className="green">Benjamin Tsubarah</span> from{" "}
            <span className="green">Malmö, Sweden.</span>
            <br />
            I am a junior web developer currently studying at Medieinstitutet in
            my city. Programming has become a significant part of my life as I
            love creating new things while maintaining them at the same time.
            <br />
            <br />
            Apart from coding, a healthy well-being both in and out of the
            workplace is also important to me, which is why I spend my limited
            spare time either in the gym or doing social activities.
          </p>

          <p style={{ color: "#00c2ada8" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  )
}

export default AboutCard
